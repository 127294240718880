import apiClient from "@/entrypoints/frontend/apiClient";
import { electionChannelSubscription } from "@/channels/ElectionChannel";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useServerConnectionStore = defineStore("serverConnection", () => {
  const sharedStore = useSharedStore();

  const lostConnectionToServer = ref<boolean>(false);
  const lostConnectionToWebsocket = ref<boolean>(false);

  const setLostConnectionToServer = (payload: boolean) => {
    lostConnectionToServer.value = payload;
  }

  const checkConnection = () => {
    apiClient.get(sharedStore.electionUrl, {timeout: 5000}).then(_ => {
      connectionSucceeded(); // we got a response from the server, so not gone anymore
    }).catch(arg => {
      if (arg.response) {
        connectionSucceeded(); // we got an error response, which means we could make a connection
      }
    });
  }

  const checkWebsocketConnection = () => {
    if (!electionChannelSubscription.value) return;
    const isDisconnected = electionChannelSubscription.value.subscription.consumer.connection.disconnected;
    if (lostConnectionToWebsocket.value && !isDisconnected) connectionToWebsocketRestored();
    else if (!lostConnectionToWebsocket.value && isDisconnected) connectionToWebsocketFailed();
  }

  const connectionFailed = () => lostConnectionToServer.value = true;

  const connectionSucceeded = () => lostConnectionToServer.value = false;

  const connectionToWebsocketFailed = () => lostConnectionToWebsocket.value = true;
  
  const connectionToWebsocketRestored = () => lostConnectionToWebsocket.value = false;

  return {
    lostConnectionToServer,
    setLostConnectionToServer,
    lostConnectionToWebsocket,
    connectionFailed,
    connectionSucceeded,
    connectionToWebsocketFailed,
    connectionToWebsocketRestored,
    checkConnection,
    checkWebsocketConnection,
  }
});
